import React from 'react'
import { ListItem, ListItemText, Theme, ListItemAvatar, ListItemButton } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  primary: {
    fontWeight: 700,
    fontSize: theme.typography.body1.fontSize,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginTop: 1,
  },
  smallPrimary: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  setMaxHeight: {
    maxHeight: 39,
  },
  secondary: {
    paddingTop: 2,
    lineHeight: '19px',
  },
}))

interface Props {
  primary?: React.ReactNode
  secondary?: React.ReactNode
  onClick?: () => void
  children?: JSX.Element
  disableGutters?: boolean
  className?: string
  small?: boolean
  avatar?: JSX.Element
}

const TableCellListItem = ({
  primary,
  secondary,
  onClick,
  children,
  disableGutters = true,
  className,
  small = false,
  avatar,
}: Props) => {
  const classes = useStyles()

  const listItemProps = {
    classes: {
      primary: small ? classes.smallPrimary : classes.primary,
      secondary: classes.secondary,
    },
    primaryTypographyProps: {
      noWrap: true,
    },
    secondaryTypographyProps: {
      noWrap: true,
    },
  }

  const ListItemTextComponent = (
    <ListItemText
      className={classes.setMaxHeight}
      primary={primary}
      secondary={secondary}
      {...listItemProps}
    />
  )

  return onClick ? (
    <ListItemButton
      dense
      disableGutters={disableGutters}
      className={className}
      onClick={onClick}
    >
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      {children}
      {ListItemTextComponent}
    </ListItemButton>
  ) : (
    <ListItem dense disableGutters={disableGutters} className={className}>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      {children}
      {ListItemTextComponent}
    </ListItem>
  )
}

export default TableCellListItem
