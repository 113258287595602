import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Grow, Box, Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    maxWidth: 665,
    margin: 'auto',
  },
  primary: {
    fontSize: 20,
  },
  avatarContainer: {
    minWidth: theme.spacing(5),
  },
  avatar: {
    width: 26,
    height: 26,
    fontSize: 17,
    color: theme.palette.common.white,
  },
  notFinal: {
    backgroundColor: theme.palette.primary.main,
  },
  finalStep: {
    backgroundColor: theme.palette.success.main,
  },
  stepContent: {
    marginLeft: theme.spacing(5),
    paddingRight: 2,
  },
  listItemText: {
    marginTop: 6,
  },
}))

interface Props {
  step: number
  requiredStep: number
  totalSteps: number
  staticStep?: boolean
  stepLabel: string
  className?: string
  children?: JSX.Element | JSX.Element[] | string
}

const StepContainer = ({
  step,
  requiredStep,
  totalSteps,
  stepLabel,
  staticStep = false,
  className,
  children,
}: Props) => {
  const classes = useStyles()
  const [show, setShow] = useState(false)

  const rootClassName = classNames(
    {
      [classes.root]: true,
    },
    className
  )

  useEffect(() => {
    if (staticStep && step >= requiredStep) {
      setShow(true)
    } else if (!staticStep && step === requiredStep) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [step, staticStep, requiredStep, show])

  return (
    <Grow in={show} mountOnEnter unmountOnExit>
      <Box className={rootClassName}>
        <List>
          <ListItem disableGutters>
            <ListItemAvatar className={classes.avatarContainer}>
              <Avatar
                className={classNames([classes.avatar], {
                  [classes.notFinal]: requiredStep < totalSteps,
                  [classes.finalStep]: requiredStep >= totalSteps,
                })}
              >
                {requiredStep + 1}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              className={classes.listItemText}
              primary={stepLabel}
              classes={{
                primary: classes.primary,
              }}
            />
          </ListItem>
        </List>
        <Box className={classes.stepContent}>{children}</Box>
      </Box>
    </Grow>
  )
}

export default StepContainer
