import { useState } from 'react'
import moment, { Moment } from 'moment'
import { DateRangeProps } from 'utils'
import { DateRange, PickersShortcutsItem } from '@mui/x-date-pickers-pro';

import {PickersShortcuts} from './PickersShortcuts'

import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputDateTimeRangeField';
import { SingleInputDateRangeField } from './SingleInputDateRangeField';

import {
  renderDigitalClockTimeView,
} from '@mui/x-date-pickers/timeViewRenderers';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

interface Props {
  onChange: (name: string | undefined, dateRange: DateRangeProps | null) => void
  name?: string
  minDate?: moment.Moment
  maxDate?: moment.Moment
  value?: DateRangeProps
  withTime?: boolean
  allowSelectFuture?: boolean
  fullWidth?: boolean
  className?: string
  allowNullValues?: boolean
  placeholder?: string
  label?: string
  large?: boolean
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  largeButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    height: 56,
    borderColor: theme.palette.border,
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
}))

export const shortcutsItems: PickersShortcutsItem<DateRange<Moment>>[] = [
  {
    label: 'Today',
    id: 'today',
    getValue: () => {
      const now = moment();
      return [now.startOf('day'), now.clone().endOf('day')];
    },
  },
  {
    label: 'Yesterday',
    id: 'yesterday',
    getValue: () => {
      const now = moment();
      const yesterday = now.subtract(1, 'day');
      return [yesterday.clone().startOf('day'), yesterday.clone().endOf('day')];
    },
  },
  {
    label: 'Last 7 days',
    id: 'last7Days',
    getValue: () => {
      const today = moment().startOf('day');
      return [today.clone().subtract(7, 'day').startOf('day'), today.clone().endOf('day')];
    },
  },
  {
    label: 'Last 14 days',
    id: 'last14Days',
    getValue: () => {
      const today = moment().startOf('day');
      return [today.clone().subtract(14, 'day').startOf('day'), today.clone().endOf('day')];
    },
  },  
  {
    label: 'Last 30 days',
    id: 'last30Days',
    getValue: () => {
      const today = moment().startOf('day');
      return [today.clone().subtract(30, 'day').startOf('day'), today.clone().endOf('day')];
    },
  },

  {
    label: 'This week',
    id: 'thisWeek',
    getValue: () => {
      const today = moment().startOf('day');
      return [today.clone().startOf('week'), today.clone().endOf('week')];
    },
  },
  {
    label: 'Last week',
    id: 'lastWeek',
    getValue: () => {
      const today = moment().startOf('day');
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.clone().startOf('week'), prevWeek.clone().endOf('week')];
    },
  },
  {
    label: 'This month',
    id: 'thisMonth',
    getValue: () => {
      const today = moment().startOf('day');
      return [today.clone().startOf('month').startOf('day'), today.clone().endOf('month').endOf('day')];
    },
  },
  {
    label: 'Last month',
    id: 'lastMonth',
    getValue: () => {
      const today = moment().startOf('day');
      const startOfLastMonth = today.startOf('month').subtract(7, 'day').startOf('month');
      return [startOfLastMonth, startOfLastMonth.clone().endOf('month')];
    },
  },
];

export const DateRangePicker = ({
  onChange,
  name,
  className,
  label,
  maxDate,
  minDate,
  withTime,
  allowSelectFuture,
  allowNullValues,
  fullWidth,
  disabled,
  value,
  large,
  placeholder,
}: Props) => {
  const classes = useStyles()
  // const [open, setOpen] = useState(false)
  const [selectedShortcutId, setSelectedShortcutId] = useState<string | null>(null)

  const [minDateState, setMinDateState] = useState(minDate || undefined)
  const [maxDateState, setMaxDateState] = useState(maxDate || (allowSelectFuture ? undefined : moment().endOf('day')))

  const [currentRange, setCurrentRange] = useState<DateRangeProps>({ startDate: value?.startDate, endDate: value?.endDate })
  const handleOnChange = (dateRange: DateRange<Moment>) => {
    const startDate = dateRange[0]
    const endDate = dateRange[1]

    if(!allowNullValues) {
      if(!startDate || !endDate) {
        return
        // if(startDate) {
        //   endDate = startDate.clone()   
        // }
        // else if(endDate) {
        //   startDate = endDate.clone()
        // }
      }
    }

    if(!withTime){
      // If selected a single day, set the end date to the end of the day and the start date to the start of the day
      if(startDate && endDate && startDate.isSame(endDate, 'day')) {
        startDate.startOf('day')
        endDate.endOf('day')
      }
    }

    setCurrentRange({ startDate, endDate })
    // setOpen(false)

    onChange(name, { startDate: startDate?.clone(), endDate: endDate?.clone() })

    // try match dateRange to shortcut
    const shortcut = shortcutsItems.find((item) => {
      const [start, end] = item.getValue({ isValid: () => true })
      return start && end && start.isSame(startDate) && end.isSame(endDate)
    })
    if(!shortcut) {
      setSelectedShortcutId(null)
    }
  }

  // const handleClear = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   event.stopPropagation()
  //   onChange(name, null)
  // }

  // const toggleOpen = () => setOpen(!open)

  // const hasValue = value?.startDate && value?.endDate

  return (
    <>
      {/* <TicketRevTextField
        value={
          hasValue
            ? `${moment(value?.startDate).format('MMM D')} - ${moment(
                value?.endDate
              ).format('MMM D')}`
            : undefined
        }
        label="Date range"
        placeholder={placeholder}
        withMargin={false}
        asButton
        onClick={toggleOpen}
        startAdornment={<DateRangeIcon />}
        endAdornment={
          hasValue ? (
            <IconButton size="small" edge="end" onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          ) : undefined
        }
      />
      <DialogWrapper
        open={open}
        maxWidth="md"
        onClose={() => setOpen(false)}
        withForm={false}
        paperClassName={classes.dialogPaper}
        contentClassName={classes.dialogContent}
        contentPadding={false}
      >
        
        <div> */}
        {/* <code>
          <pre>{JSON.stringify(currentRange, null , 2)}</pre>
        </code> */}
        {withTime ? (
        <DateTimeRangePicker 
          slots={{ field: SingleInputDateTimeRangeField, shortcuts: (props) => <PickersShortcuts {...props} items={shortcutsItems} selectedId={selectedShortcutId} onSelectShortcut={(id) => setSelectedShortcutId(id)} /> }}
          label={label}
          // defaultValue={[currentRange.startDate || null, currentRange.endDate || null]}
          slotProps={{ desktopPaper: {sx: (theme) => {
            return {
              borderRadius: '8px',              
              backgroundColor: theme.palette.grey[50],
              border: '1px solid ' + theme.palette.grey[300],
            }}
          }, field: { sx: { minWidth: '360px', cursor: 'pointer'}}, textField: { placeholder: placeholder, variant: 'filled', fullWidth: fullWidth, sx: (theme) => {
            return large ? {
              '.MuiInputBase-root:before': {
                display: 'none', // This removes the underline
              },
              '& .MuiFilledInput-root': {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.text.primary,
                height: 56,
                border: '1px solid ' + theme.palette.border,
                whiteSpace: 'nowrap',
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                borderRadius: 10,
              },
              '& .MuiFilledInput-input': {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
              },
            } :null
          } } }}
          views={['day', 'hours']}
          timeSteps={{ minutes: 60 }}
          viewRenderers={{ hours: renderDigitalClockTimeView }}
          disabled={disabled}
          value={[currentRange.startDate || null, currentRange.endDate || null]}          
          minDate={minDateState}
          maxDate={maxDateState}
          format='MMM D YYYY h:mm A'     
          name={name}
          calendars={2}        
          closeOnSelect
          onChange={handleOnChange}
          className={className}           
          />
        ) :  (
          <MuiDateRangePicker
          slots={{ field: SingleInputDateRangeField, shortcuts: (props) => <PickersShortcuts {...props} items={shortcutsItems} selectedId={selectedShortcutId} onSelectShortcut={(id) => setSelectedShortcutId(id)} /> }}
          slotProps={{ desktopPaper: {sx: (theme) => {
            return {
              borderRadius: '8px',              
              backgroundColor: theme.palette.grey[50],
              border: '1px solid ' + theme.palette.grey[300],
            }}
          }, field: { sx: {  cursor: 'pointer'}}, textField: { placeholder: placeholder, variant: 'filled', fullWidth: fullWidth, sx: (theme) => {
            return {
              width: '235px',
              ...large ? {
                '.MuiInputBase-root:before': {
                  display: 'none', // This removes the underline
                },
                '& .MuiFilledInput-root': {
                  backgroundColor: theme.palette.common.white,
                  color: theme.palette.text.primary,
                  height: 56,
                  border: '1px solid ' + theme.palette.border,
                  whiteSpace: 'nowrap',
                  paddingLeft: theme.spacing(1),
                  paddingRight: theme.spacing(1.5),
                  borderRadius: 10,
                },
                '& .MuiFilledInput-input': {
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                },
              } :null
            }
          }  }}}
          disabled={disabled}
          value={[currentRange.startDate || null, currentRange.endDate || null]}
          minDate={minDateState}
          maxDate={maxDateState}
          format='MMM D YYYY'
          calendars={2}        
          closeOnSelect
          name={name}
          onChange={handleOnChange}
          className={className}          
        />
        )
              }

        {/* <StaticDateRangePicker
        displayStaticWrapperAs="mobile"
        disabled={disabled}
        value={[value.startDate, value.endDate]}
        minDate={minDate}
        maxDate={maxDate}        
        onChange={handleOnChange}
        className={className}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </React.Fragment>
        )}
      /> */}

          {/* <RangePicker
            open
            toggle={toggleOpen}
            onChange={handleOnChange}
            wrapperClassName={`${className} ${classes.wrapper}`}
          
            initialDateRange={}
          /> 
        </div>
    //   </DialogWrapper>*/}
     </>
  )
}
