// copied from https://github.com/mui/mui-x/blob/v7.18.0/packages/x-date-pickers/src/PickersShortcuts/PickersShortcuts.tsx

import { ListItemButton, } from '@mui/material';
import List, { ListProps } from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

interface PickersShortcutsItemGetValueParams<TValue> {
  isValid: (value: TValue) => boolean;
}

export interface PickersShortcutsItem<TValue> {
  label: string;
  getValue: (params: PickersShortcutsItemGetValueParams<TValue>) => TValue;
  /**
   * Identifier of the shortcut.
   * If provided, it will be used as the key of the shortcut.
   */
  id?: string;
}

export type PickersShortcutsItemContext = Omit<PickersShortcutsItem<unknown>, 'getValue'>;

export type PickerShortcutChangeImportance = 'set' | 'accept';

export interface ExportedPickersShortcutProps<TValue> extends Omit<ListProps, 'onChange'> {
  /**
   * Ordered array of shortcuts to display.
   * If empty, does not display the shortcuts.
   * @default []
   */
  items?: PickersShortcutsItem<TValue>[];
  /**
   * Importance of the change when picking a shortcut:
   * - "accept": fires `onChange`, fires `onAccept` and closes the picker.
   * - "set": fires `onChange` but do not fire `onAccept` and does not close the picker.
   * @default "accept"
   */
  changeImportance?: PickerShortcutChangeImportance;
}

interface CustomPickersShortcutsProps<TValue> extends PickersShortcutsProps<TValue> {
  selectedId: string | null
  onSelectShortcut: (id: string) => void
}

export interface PickersShortcutsProps<TValue> extends ExportedPickersShortcutProps<TValue> {
  isLandscape: boolean;
  onChange: (
    newValue: TValue,
    changeImportance: PickerShortcutChangeImportance,
    shortcut: PickersShortcutsItemContext,
  ) => void;
  isValid: (value: TValue) => boolean;
}

/**
 * Demos:
 *
 * - [Shortcuts](https://mui.com/x/react-date-pickers/shortcuts/)
 *
 * API:
 *
 * - [PickersShortcuts API](https://mui.com/x/api/date-pickers/pickers-shortcuts/)
 */


function PickersShortcuts<TValue>(props: CustomPickersShortcutsProps<TValue>) {
  const { items, changeImportance = 'accept', isLandscape, onChange, isValid, ...other } = props;

  // console.log('heree items', items);

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map(({ getValue, ...item }) => {
    const newValue = getValue({ isValid });

    return {
      ...item,
      label: item.label,
      onClick: () => {
        onChange(newValue, changeImportance, item);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <List
      dense
      sx={[
        {
          //  maxHeight: 200,
          maxWidth: 200,
          overflow: 'auto',
        },
        ...(Array.isArray(other.sx) ? other.sx : [other.sx]),
      ]}
      {...other}
    >
      {resolvedItems.map((item) => {
        return (
          <ListItem key={item.id ?? item.label} onClick={(e: any) => { props.onSelectShortcut(item.id ?? item.label) }}>
            <ListItemButton {...item} selected={item.id && props.selectedId ? item.id === props.selectedId : false}>
              {item.label}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export { PickersShortcuts };
