import React from 'react'
import { StatusCircle } from 'components'
import {
  Link,
  Theme,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Button,
  Avatar,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link as LinkIcon } from '@mui/icons-material'

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  title: string
  imgUrl?: string
  active: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    marginBottom: theme.spacing(),
    backgroundColor: theme.palette.common.white,
    height: 70,
  },
  primary: {
    fontWeight: 700,
  },
  secondary: {
    marginTop: theme.spacing(0.5),
    display: 'block',
  },
}))

const IntegrationListItem = ({ title, active, imgUrl, onClick }: Props) => {
  const classes = useStyles()

  return (
    <ListItem className={classes.listItem}>
      {imgUrl ? (
        <ListItemAvatar>
          <Avatar alt={`Integrate with ${title}`} src={imgUrl} />
        </ListItemAvatar>
      ) : (
        <ListItemIcon>
          <LinkIcon />
        </ListItemIcon>
      )}

      <ListItemText
        primary={<span className={classes.primary}>{title}</span>}
        secondary={
          <span className={classes.secondary}>
            <StatusCircle color={active ? 'success' : 'neutral'} />
            {active ? 'Active' : 'Not active'}&nbsp;&nbsp;|&nbsp;&nbsp;
            <Link href="https://lmgtfy.com/?q=what+is+a+ticket+provider" target="_blank">
              Learn more
            </Link>
          </span>
        }
      />
      <ListItemSecondaryAction>
        <Button
          color="primary"
          onClick={onClick}
          variant="outlined"
          size="small"
          disabled={active}
        >
          Activate
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default IntegrationListItem
