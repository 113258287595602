import React from 'react'
import {
  Typography,
  Theme,
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  ListItemText,
  ListItemIcon,
  alpha,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import { Skeleton } from '@mui/material';

interface Props {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  title?: React.ReactNode
  description?: React.ReactNode
  selected?: boolean
  icon?: React.ReactNode
  loading?: boolean
  disabled?: boolean
  className?: string
  skeleton?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    overflow: 'hidden',
    backgroundColor: 'transparent',
  },
  disabled: {
    opacity: 0.7,
  },
  content: {},
  selected: {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
  },
  title: {
    fontWeight: 400,
    fontSize: theme.typography.body1.fontSize,
    marginLeft: theme.spacing(2),
  },
  listItem: {
    padding: 0,
    marginBottom: theme.spacing(2),
  },
}))

const ChannelCard = ({
  title,
  description,
  selected,
  icon,
  onClick,
  loading,
  disabled,
  className,
  skeleton,
}: Props) => {
  const classes = useStyles()

  const root = classNames(
    [classes.root],
    {
      [classes.disabled]: disabled,
      [classes.selected]: selected,
    },
    className
  )

  return skeleton ? (
    <Card className={root} style={{ width: '100%', height: '100%' }}>
      <CardContent className={classes.content} style={{ width: '100%', height: '100%' }}>
        <Skeleton animation="wave" width="100%" height="100%" variant="rectangular" />
      </CardContent>
    </Card>
  ) : (
    <Card className={root}>
      <CardActionArea onClick={onClick} disabled={disabled || loading}>
        <CardContent className={classes.content}>
          <ListItem className={classes.listItem}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
              primary={loading ? <Skeleton variant="text" /> : title}
              primaryTypographyProps={{ className: classes.title }}
            />
          </ListItem>
          {description && typeof description === 'string' ? (
            <Typography color="textSecondary" variant="body2">
              {loading ? <Skeleton variant="text" /> : description}
            </Typography>
          ) : (
            description
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ChannelCard
