import { AppBar } from 'components';
import { useFormik } from 'formik';
import { UserGrantedOrganisation } from '@seeka-labs-internal/lib-api-account';
import { LoginProviderInfo } from '@seeka-labs-internal/lib-api-app';
import { useMe } from 'hooks';
import { sortBy } from 'lodash-es';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import loginManager from 'services/loginManager';
import { getToken, removeToken, setToken, useDocumentTitle } from 'utils';
import useShopifyAppInstallContext from 'utils/useShopifyAppInstallContext';

import { Button, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSeekaConverge } from '@seeka-labs/converge-react';

import {
  Container, ContentCard, OrSeparator, SelectOrganisationDialog, Title
} from '../components';
import LoginForm, { FormProps } from './Form';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    marginTop: 6,
    marginLeft: 2,
    marginBottom: theme.spacing(1.5),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

const initialValues: FormProps = {
  email: '',
  password: '',
}

interface Props extends RouteComponentProps {
  loginProviders: LoginProviderInfo[]
  /** used to remove invite token on route change in case of error */
  hasLoginProvidersError: boolean
  getRecaptchaToken: () => Promise<string | undefined>
}

const Login = ({
  loginProviders,
  hasLoginProvidersError,
  getRecaptchaToken,
}: Props) => {
  const classes = useStyles()
  useDocumentTitle('Sign in')
  const token = getToken()
  const history = useHistory();

  const shopifyInstallContext = useShopifyAppInstallContext();
  const { shopifyContextId, shopifyDomain, shopifyStoreName, hasContext } = shopifyInstallContext;


  const [isShopifyLandingShown, setIsShopifyLandingShown] = useState(hasContext)

  useEffect(() => {
    setIsShopifyLandingShown(hasContext)
  }, [hasContext])

  const [orgsToSelect, setOrgsToSelect] = useState<UserGrantedOrganisation[] | null>(null)

  const { data: meData, isLoading } = useMe({
    enabled: Boolean(token),
    retry: 1,
    onError: () => removeToken(),
  })

  const handleGoToCreateAccount = () => {
    history.push(`/sign-up${hasLoginProvidersError ? '' : history.location.search}`)
  }

  useEffect(() => {
    if (meData && getToken()) history.push('/dashboard/signals')
  }, [meData])

  const converge = useSeekaConverge();

  const {
    setFieldValue,
    handleSubmit,
    values,
    isSubmitting,
    setSubmitting,
    errors,
    setErrors,
  } = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (payload) => {
      setSubmitting(true)
      try {
        const recaptchaToken = await getRecaptchaToken()
        if (!recaptchaToken) return
        const loginOrgId = await loginManager.getOrgForLogin(payload.email)
        // no account
        if (!loginOrgId) {
          setErrors({
            email: `We couldn't find an account associated with ${payload.email}.`,
          })
          setSubmitting(false)
        } else {
          try {
            const loginResponse = await loginManager.loginOrThrow(
              payload.email,
              payload.password,
              loginOrgId,
              recaptchaToken
            )
            setToken(loginResponse.accessToken)

            await converge.identity.mergeProfile({
              email: [payload.email]
            })
            await converge.track.custom('WebAppLogin');
          } catch (reason) {
            const errorToObject: any = reason ? { ...reason } : undefined
            setErrors({
              password:
                ('body' in errorToObject &&
                  'error_description' in errorToObject.body &&
                  errorToObject.body.error_description) ||
                'Password is incorrect. Please try again.',
            })
            setSubmitting(false)
          }
        }
      } catch (error) {
        setErrors({
          email: `An error occurred`,
        })
      }
    },
  })

  const handleGoToResetPassword = () => history.push('/account-recovery')

  const setFieldValueWrapper = (name: string, value: any) => {
    setFieldValue(name, value)
    // eslint-disable-next-line
    if (errors.hasOwnProperty(name)) {
      setErrors({ ...errors, [name]: undefined })
    }
  }

  return (
    <>
      <Container>
        <AppBar>
          {!isShopifyLandingShown && (
            <span>
              <Typography display="inline" align="right">
                Don&apos;t have an account?{' '}
              </Typography>
              <Typography
                display="inline"
                align="right"
                color="primary"
                onClick={handleGoToCreateAccount}
                className={classes.link}
              >
                Sign up
              </Typography>
            </span>)}
        </AppBar>
        <ContentCard containerHeight={850}>


          {!isShopifyLandingShown ? (
            <>
              <Title title="Sign in" />
              <LoginForm
                setFieldValue={setFieldValueWrapper}
                handleGoToResetPassword={handleGoToResetPassword}
                loginProviders={loginProviders}
                isLoading={isLoading || isSubmitting}
                handleSubmit={handleSubmit}
                values={values}
                errors={errors}
              /></>) : <>
            <Title title="Let's get your Shopify integration setup!" />
            <div style={{ margin: '0 auto', display: 'table' }}>
              <Button variant='contained' color='primary' disableElevation style={{ marginBottom: '30px' }} onClick={() => setIsShopifyLandingShown(false)}>I have a Seeka account</Button>
            </div>
            <OrSeparator />
            <div style={{ margin: '0 auto', display: 'table' }}>
              <Button variant='contained' color='primary' disableElevation onClick={handleGoToCreateAccount}>I want to create a Seeka account</Button>
            </div>
          </>}
        </ContentCard>
      </Container>
      {orgsToSelect && (
        <SelectOrganisationDialog
          {...values}
          organisations={orgsToSelect}
          setErrors={setErrors}
          handleClose={() => setOrgsToSelect(null)}
          getRecaptchaToken={getRecaptchaToken}
        />
      )}
    </>
  )
}

export default Login
